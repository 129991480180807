import * as React from 'react';
import { Link, Outlet } from 'react-router-dom';
import {
    Box,
    Container,
    AppBar,
    Toolbar,
    Typography,
    Stack,
    Grid,
    Menu,
    MenuItem,
    IconButton,
    Button,
    Tooltip,
    BottomNavigation,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import siteLogo from '../../_asset/logo.png';
import { getSystemInfo } from '../../_action/login.action';

const pages = ['Packages', 'Accounts', 'Settings', 'Vintages', 'Admins'];

const indexToTabName = {
    packages: 0,
    accounts: 1,
    settings: 2,
    vintages: 3,
    admins: 4,
};

export const Main = () => {
    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [selectedTab, setSelectedTab] = React.useState(
        sessionStorage.getItem('path') ? indexToTabName[sessionStorage.getItem('path').substr(1)] : 0,
    );
    const [path] = React.useState(sessionStorage.getItem('path') ? sessionStorage.getItem('path') : window.location.pathname);
    const [userInfo, setUserInfo] = React.useState({});
    const [systemInfo, setSystemInfo] = React.useState({});

    React.useEffect(() => {
        if (path !== '/' && !path.includes('index.html')) {
            sessionStorage.setItem('path', path);
        }
        switch (path) {
            case '/packages':
                setSelectedTab(0);
                break;
            case '/accounts':
                setSelectedTab(1);
                break;
            case '/settings':
                setSelectedTab(2);
                break;
            case '/vintages':
                setSelectedTab(3);
                break;
            case '/admins':
                setSelectedTab(4);
                break;
            default:
                setSelectedTab(0);
                break;
        }

        let userInfo = sessionStorage.getItem('userInfo');
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
            setUserInfo(userInfo);
        }
    }, [path]);

    React.useEffect(() => {
        getSystemInfo().then((res) => {
            setSystemInfo(res.data);
        });
    }, []);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleLogOut = () => {
        sessionStorage.clear();
        navigate('/login');
    };

    const handleSetMenuIndex = (page, i) => {
        setAnchorElNav(null);
        sessionStorage.setItem('path', '/' + page.toLowerCase());
        setSelectedTab(i);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <AppBar
                position='static'
                sx={{ bgcolor: 'white' }}
            >
                <Container maxWidth='false'>
                    <Toolbar disableGutters>
                        <Typography
                            variant='h6'
                            noWrap
                            component='a'
                            href='/'
                            sx={{
                                mt: 1,
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: '#000',
                                textDecoration: 'none',
                                maxWidth: 160,
                            }}
                        >
                            <Stack spacing={0}>
                                <Box
                                    component='img'
                                    sx={{
                                        height: 53,
                                        width: 153,
                                    }}
                                    alt='RouteSmart Logo'
                                    src={siteLogo}
                                />
                                <Typography sx={{ color: '#666', fontSize: 12 }}>{systemInfo.Environment}</Typography>
                            </Stack>
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size='large'
                                aria-label='account of current user'
                                aria-controls='menu-appbar'
                                aria-haspopup='true'
                                onClick={handleOpenNavMenu}
                                color='inherit'
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id='menu-appbar'
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem
                                        key={page}
                                        onClick={handleCloseNavMenu}
                                        component={Link}
                                        to={'/' + page.toLowerCase()}
                                    >
                                        <Typography textAlign='center'>{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        <Typography
                            variant='h5'
                            noWrap
                            component='a'
                            href=''
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <Box
                                component='img'
                                sx={{
                                    height: 53,
                                    width: 153,
                                }}
                                alt='RouteSmart Logo'
                                src={siteLogo}
                            />
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page, i) => (
                                <Button
                                    component={Link}
                                    key={page}
                                    to={'/' + page.toLowerCase()}
                                    onClick={() => handleSetMenuIndex(page, i)}
                                    sx={
                                        selectedTab === i
                                            ? {
                                                  my: 2,
                                                  color: 'white',
                                                  fontWeight: 600,
                                                  display: 'block',
                                                  backgroundColor: '#31679e',
                                                  '&.MuiButtonBase-root:hover': {
                                                      color: 'white',
                                                      bgcolor: '#31679e',
                                                  },
                                              }
                                            : {
                                                  my: 2,
                                                  color: '#31679e',
                                                  fontWeight: 600,
                                                  display: 'block',
                                                  backgroundColor: 'white',
                                                  '&.MuiButtonBase-root:hover': {
                                                      color: '#31679e',
                                                      bgcolor: 'white',
                                                  },
                                              }
                                    }
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0, mr: 2 }}>
                            <Typography sx={{ p: 0, color: '#31679e', fontSize: 16 }}>Welcome {userInfo.FirstName}</Typography>
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title='Log out'>
                                <IconButton
                                    onClick={handleLogOut}
                                    sx={{ p: 0, color: '#31679e', fontSize: 16 }}
                                >
                                    <LogoutIcon /> Logout
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Outlet />
            <Paper
                sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1 }}
                elevation={3}
            >
                <BottomNavigation showLabels>
                    <Stack>
                        <Typography
                            align='center'
                            sx={{ width: '100%', marginTop: ' 5px' }}
                        >
                            Copyright © RouteSmart Technologies, Inc. {new Date().getFullYear()}.
                        </Typography>
                        <Typography sx={{ mr: 1, color: 'rgba(0, 0, 0, 0.6)', whiteSpace: 'nowrap' }}>
                            Data Cloud v.{systemInfo.BuildVersion} ({systemInfo.BuildDate ? systemInfo.BuildDate.substr(0, 10) : ''})
                        </Typography>
                    </Stack>
                </BottomNavigation>
            </Paper>
        </Box>
    );
};

export const ConfirmDialog = (props) => {
    const { confirmOpen, confirmTitle, confirmContent, handleConfirmDialogClose } = props;
    return (
        <Dialog
            maxWidth={'xs'}
            fullWidth={true}
            open={confirmOpen}
        >
            <DialogTitle>{confirmTitle}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography>{confirmContent}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleConfirmDialogClose('CANCEL')}>Cancel</Button>
                <Button onClick={() => handleConfirmDialogClose('SAVE')}>Proceed</Button>
            </DialogActions>
        </Dialog>
    );
};

export const AlertDialog = (props) => {
    const { alertOpen, alertTitle, alertContent, handleAlertDialogClose } = props;
    return (
        <Dialog
            maxWidth={'xs'}
            fullWidth={true}
            open={alertOpen}
        >
            <DialogTitle>{alertTitle}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography>{alertContent}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleAlertDialogClose()}>Okay</Button>
            </DialogActions>
        </Dialog>
    );
};
